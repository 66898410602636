import React, { Fragment, useEffect, useState } from "react";

import Header from "../../component/header/Header";

import BlogItem from "./BlogItem";
import classes from "./Blog.module.css";
import Footer from "../../component/footer/Footer";
import Hero from "../../component/hero/Hero";

import empty_image from "../../assets/Blog/empty_blog3.png";

const Blog = () => {
  const [bloglist, setBloglist] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBlog = async () => {
    const response = await fetch(
      "https://rea-apis.azurewebsites.net/web/post",
      { mode: "cors" }
    );

    if (!response.ok) {
      alert("error");
      throw new Error("couldn't fetch data");
    }
    const data = await response.json();

    setLoading(false);
    setBloglist(data);
  };

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <Fragment>
      <Header />
      <Hero pageTitle={"BLOG"} blog={true} />
      <section className={classes["empty_blog"]}>
        {" "}
        <img src={empty_image} alt="empty blog" />
        <h2>Oops! Nothing Here!</h2>
        <p>We have no blogs at the moment, check back later</p>
      </section>

      {loading ? (
        <div className={classes["loading-spinner-container"]}>
          <div className={classes["loading-spinner"]}></div>
        </div>
      ) : (
        <section className={classes.blog}>
          {bloglist.map((item) => (
            <BlogItem data={item} />
          ))}
        </section>
      )}
      <Footer home={false} />
    </Fragment>
  );
};

export default Blog;
