// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileNav_mobileNav__jwNUc {\n  height: 100vh;\n  margin: 0 2rem;\n  text-align: center;\n}\n\n.MobileNav_mobileNav__jwNUc ul {\n  padding: 0;\n  text-align: left;\n}\n\n.MobileNav_mobileNav__jwNUc li {\n  font-size: 24px;\n  list-style-type: none;\n  margin-bottom: 1rem;\n}\n\n.MobileNav_mobileNav__jwNUc li a {\n  color: white;\n  text-decoration: none;\n}\n\n.MobileNav_button__9Ca1F {\n  background: none;\n  border: 2px solid #6c63fe;\n  color: #6c63fe;\n  font-family: \"inter\";\n  font-size: 20px;\n  margin-top: 5rem;\n  padding: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/component/header/MobileNav.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".mobileNav {\n  height: 100vh;\n  margin: 0 2rem;\n  text-align: center;\n}\n\n.mobileNav ul {\n  padding: 0;\n  text-align: left;\n}\n\n.mobileNav li {\n  font-size: 24px;\n  list-style-type: none;\n  margin-bottom: 1rem;\n}\n\n.mobileNav li a {\n  color: white;\n  text-decoration: none;\n}\n\n.button {\n  background: none;\n  border: 2px solid #6c63fe;\n  color: #6c63fe;\n  font-family: \"inter\";\n  font-size: 20px;\n  margin-top: 5rem;\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileNav": "MobileNav_mobileNav__jwNUc",
	"button": "MobileNav_button__9Ca1F"
};
export default ___CSS_LOADER_EXPORT___;
