import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./MobileNav.module.css";

const MobileNav = () => {
  const scrollUpHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className={classes.mobileNav}>
        <ul className={`${classes.flex} ${classes.links}`}>
          <li onClick={scrollUpHandler}>
            <NavLink className={classes.home} to="/">
              HOME
            </NavLink>
          </li>
          <li onClick={scrollUpHandler}>
            <NavLink className={classes.about} to="/about">
              ABOUT US
            </NavLink>
          </li>
          <li onClick={scrollUpHandler}>
            <NavLink className={classes.blog} to="/blog">
              BLOG
            </NavLink>
          </li>
          <li>
            <NavLink className={classes.contact}>CONTACT US</NavLink>
          </li>
        </ul>
        <button className={classes.button}>Download the App </button>
      </div>
    </>
  );
};

export default MobileNav;
