// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThemeMode_theme-mode__qDShW {\n  display: flex;\n  background-color: #6c63fe;\n  border: none;\n  border-radius: 50%;\n  padding: 10px;\n  cursor: pointer;\n}\n\n@media screen and (max-width: 768px) {\n  .ThemeMode_theme-mode__qDShW {\n    display: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/component/themeMode/ThemeMode.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".theme-mode {\n  display: flex;\n  background-color: #6c63fe;\n  border: none;\n  border-radius: 50%;\n  padding: 10px;\n  cursor: pointer;\n}\n\n@media screen and (width <= 768px) {\n  .theme-mode {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-mode": "ThemeMode_theme-mode__qDShW"
};
export default ___CSS_LOADER_EXPORT___;
