import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import classes from "./BlogDetails.module.css";
import GoBack from "../../component/GoBack";

const BlogDetails = () => {
  const params = useParams();
  const { blogId } = params;
  const [blogDetails, setBlogDetails] = useState([]);
  const [error, setError] = useState(null);

  const getDetails = useCallback(async () => {
    try {
      const response = await fetch(
        `https://rea-apis.azurewebsites.net/web/post/${blogId}`,
        { mode: "cors" }
      );

      if (!response.ok) {
        alert("error");
        throw new Error("couldn't fetch data");
      }
      const data = await response.json();
      setBlogDetails(data);
    } catch (error) {
      setError(error.message);
    }
  }, [blogId, setBlogDetails, setError]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const { title, content, photo } = blogDetails;

  return (
    <Fragment>
      <Header pageTitle={"BLOG"} blog={true} />
      <GoBack />
      {error ? (
        <p>{error}</p>
      ) : (
        <section className={classes.container}>
          <h1>{title}</h1>
          <img src={photo} alt="blog" className={classes["blog-image"]} />

          <p className={classes.text}>{content}</p>
        </section>
      )}
      <Footer />
    </Fragment>
  );
};

export default BlogDetails;
