import React from "react";

import styled from "styled-components";

const ButtonUI = styled.button`
  background-color: #56556b;
  border: 2px solid #6c63fe;
  color: white;
  font-family: "inter";
  padding: 10px 25px;
`;

const Button = (props) => {
  return <ButtonUI className={props.className}>{props.buttonTitle}</ButtonUI>;
};

export default Button;
