import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, NavLink } from "react-router-dom";

import logo from "../../assets/n-logo.png";

import classes from "./Header.module.css";
import MobileNav from "./MobileNav";
import ThemeMode from "../../component/themeMode/ThemeMode";

const Header = () => {
  const isLightMode = useSelector((state) => state.themeReducer.lightMode);
  const [isNavOpen, setIsNavOpen] = useState(false);

  console.log(isLightMode);

  return (
    <>
      <header
        className={`${
          isLightMode ? classes["light-mode"] : classes["dark-mode"]
        } ${classes.header}`}
      >
        <nav
          className={`${classes.flex} ${
            classes[isNavOpen ? "background" : "nav-bar"]
          }`}
        >
          <div className={classes.flex}>
            <NavLink to="/">
              <img className={classes.logo} src={logo} alt="logo" />
            </NavLink>

            <ul className={`${classes.flex} ${classes.links}`}>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `${isActive ? classes.active : ""} ${classes.home}`
                  }
                  to="/"
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `${isActive ? classes.active : ""} ${classes.home}`
                  }
                  to="/about"
                >
                  ABOUT US
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `${isActive ? classes.active : ""} ${classes.home}`
                  }
                  to="/blog"
                >
                  BLOG
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={classes.flex}>
            {" "}
            <ThemeMode />
            <button className={classes.button}>Download the App </button>
          </div>

          <div className={classes["nav-logo"]}>
            <div
              onClick={() => setIsNavOpen(!isNavOpen)}
              className={`${classes[isNavOpen ? "is-active" : "bars"]} ${
                classes.bars
              }
              }`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </nav>
        {isNavOpen && (
          <section className={`${isNavOpen && classes.mobileNavOverlay}`}>
            <section
              className={`${classes[isNavOpen ? "shownavs" : ""]} ${
                classes.mobileNavLinks
              }`}
            >
              <MobileNav />
            </section>
          </section>
        )}
      </header>
      <Outlet />
    </>
  );
};

export default Header;
