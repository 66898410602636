import { Link } from "react-router-dom";
import classes from "./Hero.module.css";
import Button from "../../UI/Button/Button";
const Hero = ({
  title,
  span,
  content,
  buttonTitle,
  pageTitle,
  home,
  about,
  blog,
}) => {
  return (
    <section
      className={` ${home ? classes["bg-home"] : ""} ${
        about ? classes["bg-about"] : ""
      } ${blog ? classes["bg-blog"] : ""}`}
    >
      {!home && (
        <h1
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
          className={classes.pageTitle}
        >
          {pageTitle}
        </h1>
      )}
      {home && (
        <div className={classes["hero-content"]}>
          <h1 data-aos="flip-up" data-aos-duration="1000">
            {" "}
            {title}
            <br />
            <span>{span}</span>
          </h1>
          <p>{content}</p>
          <Link to="/about">
            {" "}
            <Button
              data-aos="zoom-in"
              className={classes.button}
              buttonTitle={buttonTitle}
            />
          </Link>
        </div>
      )}
    </section>
  );
};

export default Hero;
