// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"SF Pro Display\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.App {\n  background-color: blue;\n  font-family: \"SF Pro Display\";\n  text-align: center;\n}\n\n/* html {\n  scroll-behaviour: smooth;\n} */\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4CAA0E;AAC5E;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;;GAEG","sourcesContent":["@font-face {\n  font-family: \"SF Pro Display\";\n  src: url(\"../src/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF\");\n}\n\n.App {\n  background-color: blue;\n  font-family: \"SF Pro Display\";\n  text-align: center;\n}\n\n/* html {\n  scroll-behaviour: smooth;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
