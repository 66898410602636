import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import About from "./pages/about/about";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog/BlogDetails";

import LandingPage from "./pages/landing/LandingPage";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogId" element={<BlogDetails />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
