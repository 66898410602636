import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const GoBack = () => {
  const [hover, setHover] = useState(false); // Add a hover state

  const navigate = useNavigate();

  const scrollUpHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const backHandler = () => {
    navigate(-1);
    scrollUpHandler();
  };

  const buttonStyle = {
    display: "inline-block",
    backgroundColor: "#f2f2f2",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px 16px",
    textDecoration: "none",
    color: "#333",
    fontSize: "18px",
    marginLeft: "2rem",
    cursor: "pointer",
    alignItems: "center",
  };

  const arrowStyle = {
    position: "absolute",
    left: "-20px", // adjust as needed
    transition: "all 0.2s ease-in-out", // added transition property
  };

  const hoverStyle = {
    backgroundColor: "#ddd",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // added boxShadow property
  };

  return (
    <>
      <p
        onClick={backHandler}
        style={{ ...buttonStyle, ...(hover ? hoverStyle : null) }} // Use spread operator to merge buttonStyle and hoverStyle when hover is true
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {" "}
        <span style={arrowStyle}>
          <FaArrowLeft />
        </span>
        Go Back
      </p>
    </>
  );
};

export default GoBack;
