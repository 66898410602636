import React, { Fragment } from "react";

import Button from "../../UI/Button/Button";
// import image from "../../assets/Blog/blog-photo-one.jpeg";

import classes from "./BlogItem.module.css";
import { Link } from "react-router-dom";

const BlogItem = ({ data }) => {
  const { id, title, content, photo } = data;
  const newContent = content.split(`.`);

  const scrollUpHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Fragment>
      <section className={classes.container}>
        <h1>{title}</h1>
        <img src={photo} alt="blog" className={classes["blog-image"]} />
        <p className={classes.text}>{newContent[0]}</p>

        <Link to={`/blog/${id}`} onClick={scrollUpHandler}>
          <Button className={classes.button} buttonTitle={"Read More"} />
        </Link>
      </section>
    </Fragment>
  );
};

export default BlogItem;
