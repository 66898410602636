import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store/index";

// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Landing from "./pages/landing/landing";
// import About from "./pages/about/about";

// const router = createBrowserRouter([
//   {
//     path: "*",
//     element: <Landing />,
//   },
//   {
//     path: "about/",
//     element: <About />,
//   },
// ]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
      {/* <RouterProvider router={router} /> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
