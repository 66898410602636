import React from "react";
import { useDispatch, useSelector } from "react-redux";

import darkMode from "../../assets/moon.svg";
import lightMode from "../../assets/sun.svg";
import { themeAction } from "../../store/theme-slice";

import classes from "./ThemeMode.module.css";
const ThemeMode = () => {
  const dispatch = useDispatch();
  //   const [isLightMode, setIsLightMode] = useState(false);
  //   const [isDarkMode, setIsDarkMode] = useState(true);

  const isLightMode = useSelector((state) => state.themeReducer.lightMode);
  const isDarkMode = useSelector((state) => state.themeReducer.darkMode);

  const toggleModeHandler = () => {
    // setIsDarkMode(!isDarkMode);
    // setIsLightMode(!isLightMode);
    dispatch(themeAction.toggleModeHandler());
  };

  return (
    <>
      {isDarkMode && (
        <button className={classes["theme-mode"]} onClick={toggleModeHandler}>
          {" "}
          <img src={lightMode} alt="lightmode" />
        </button>
      )}

      {isLightMode && (
        <button className={classes["theme-mode"]} onClick={toggleModeHandler}>
          <img src={darkMode} alt="lightode" />
        </button>
      )}
    </>
  );
};

export default ThemeMode;
