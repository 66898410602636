import { createSlice } from "@reduxjs/toolkit";

const ThemeSlice = createSlice({
  name: "cart",
  initialState: {
    lightMode: true,
    darkMode: false,
  },
  reducers: {
    toggleModeHandler(state) {
      state.darkMode = !state.darkMode;
      state.lightMode = !state.lightMode;
    },
  },
});

export const themeAction = ThemeSlice.actions;
export default ThemeSlice;
