import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Footer from "../../component/footer/Footer";
import Header from "../../component/header/Header";
import Hero from "../../component/hero/Hero";

import iphone from "../../assets/Home/iPhone.png";
import iphone1 from "../../assets/Home/iPhone1.png";
import finance from "../../assets/Home/financials.png";
import quality from "../../assets/Home/quality.png";
import secure from "../../assets/Home/secure.png";

import classes from "./landing.module.css";

const Landing = () => {
  const HeroContainer = {
    title: "WE ARE",
    span: "NEUTROLABS",
    content: `Founded for the sole purpose of building products to solve real problems 
      by leveraging blockchain technology as a solution to real world problems.
       Our vision is to become a global platform that offers security and ease 
       through technology.`,
  };

  const isLightMode = useSelector((state) => state.themeReducer.lightMode);

  return (
    <>
      <Header />
      <main
        className={isLightMode ? classes["light-mode"] : classes["dark-mode"]}
      >
        <Hero
          title={HeroContainer.title}
          span={HeroContainer.span}
          content={HeroContainer.content}
          buttonTitle={"GET STARTED"}
          home={true}
        />
        <section className={classes["container-one"]}>
          <h4 data-aos="flip-left" data-aos-duration="1500">
            INTRODUCING
          </h4>
          <h3>REA</h3>
          <div className={classes["content-one"]}>
            <p>
              Reá is a digital real estate investment platform that offers
              fractional ownership which enables people to grow their wealth by
              collectively investing in income-generating properties. With Rea,
              real estate investments are made easy,less risky and hassle-free
              for people by allowing people to invest for a low amount.
            </p>
            <div>
              <img src={iphone} alt="iphone" />
              <img src={iphone1} alt="iphone1" />
            </div>
          </div>
        </section>

        <section className={classes["container-two"]}>
          <h4>
            WHY <span>REA</span>
          </h4>

          <div className={classes["content-two"]}>
            <div
              className={classes.financial}
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <img src={finance} alt="finance" />
              <h3>Financial Growth</h3>
              <p>
                Watch your investment grow as the property value appreciates and
                also earn passive income consistently from monthly rental
                payments.
              </p>
            </div>
            <div
              className={classes.quality}
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <img src={quality} alt="secure" />
              <h3>Quality</h3>
              <p>
                Rea source the best properties from different locations around
                the world with the highest investment potential for you.
              </p>
            </div>
            <div
              className={classes.security}
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <img src={secure} alt="secure" />
              <h3>Security</h3>
              <p>
                Rea partners with top real estate developers with an experienced
                and diverse team. We are trusted and regulated by the SEC. We
                aim to make your investment journey hassle-free with the use of
                cutting edge technology to make transactions easy,fast and
                reliable.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer home={true} />

      <Outlet />
    </>
  );
};

export default Landing;
