import { Link } from "react-router-dom";

import neutroIcon from "../../assets/Footer/logo.png";
import socials from "../../assets/Footer/Social.png";

import classes from "./Footer.module.css";
import { useRef } from "react";

const Footer = ({ home }) => {
  const emailInputRef = useRef();

  const subscribeHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    try {
      const response = await fetch(
        "https://rea-apis.azurewebsites.net/web/subcribe/",
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: enteredEmail }),
        }
      );

      if (!response.ok) {
        alert("error");
        throw new Error("couldn't fetch data");
      }

      const data = await response.json();
      console(data);
    } catch (error) {}
  };

  const scrollUpHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <footer className={classes.footer}>
        {home && (
          <section className={`${classes.flex} ${classes.top}`}>
            <div className={classes.subscribe}>
              <p className={classes.sub}>
                SUBSCRIBE TO OUR
                <br />
                <span className={classes.newslet}>NEWSLETTER</span>
              </p>
            </div>

            <form
              onSubmit={subscribeHandler}
              className={`${classes.flex} ${classes.form}`}
            >
              <input
                type="email"
                className={classes.email}
                name="email"
                placeholder="Email Address"
                ref={emailInputRef}
              />
              <button className={classes.button}>SUBSCRIBE</button>
            </form>
          </section>
        )}

        <section className={`${classes.flex} ${classes.bottom}`}>
          <div className={classes["ff"]}>
            <div className={classes["menu"]}>
              <div className={classes["mmm"]}>
                <h5>MENU</h5>
                <div className={classes["bottom-content"]}>
                  {" "}
                  <ul>
                    <li onClick={scrollUpHandler}>
                      <Link to="/">Home</Link>
                    </li>
                    <li onClick={scrollUpHandler}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li onClick={scrollUpHandler}>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li onClick={scrollUpHandler}>
                      <Link to="">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes["what"]}>
              <h5>What We Do</h5>
              <div className={classes["bottom-content"]}>
                <div>
                  <p>Encouraging Testing</p>
                </div>

                <div>
                  <p>Strengthening Advocacy</p>
                </div>

                <div>
                  <p>Sharing Information</p>
                </div>

                <div>
                  <p>Building Leadership</p>
                </div>

                <div>
                  <p>Engaging With Global Fund</p>
                </div>

                <div>
                  <p>Shining a Light</p>
                </div>
              </div>
            </div>

            <div className={classes["legal"]}>
              <h5>LEGAL</h5>
              <div className={classes["bottom-content"]}>
                <div>
                  <p>General Info</p>
                </div>

                <div>
                  <p>Privacy Policy</p>
                </div>

                <div>
                  <p>Terms of Service</p>
                </div>
              </div>
            </div>

            <div className={classes["talk"]}>
              <h5>TALK TO US</h5>
              <div className={classes["bottom-content"]}>
                <div>
                  <p>neutroin@mail.info</p>
                </div>

                <div>
                  <p>+234 8125997475</p>
                </div>

                <div>
                  <p>Contact Us</p>
                </div>

                <div>
                  <p>Facebook</p>
                </div>

                <div>
                  <p>Linkedin</p>
                </div>

                <div>
                  <p>Twitter</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className={classes["grey"]}></div>

        <div className={classes["ncs"]}>
          <img
            className={classes["neutroIcon"]}
            src={neutroIcon}
            alt="neutroIcon"
          />
          <div className={classes["copyr"]}>
            © 2023 Neutro Labs. All Rights Reserved.
          </div>
          <img className={classes["socials"]} src={socials} alt="socials" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
