import { useEffect, useState } from "react";

import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import microsoft365 from "../../assets/partnerLogo/microsoft_365.svg.png";
import azure from "../../assets/partnerLogo/azure.svg";
import github from "../../assets/partnerLogo/GitHub_Logo.png";
import vscode from "../../assets/partnerLogo/vscode.png";
import clarity from "../../assets/partnerLogo/clarity.png";
import openai from "../../assets/partnerLogo/openai.svg";
import linkedIn from "../../assets/partnerLogo/linkedin.svg";
import ansarada from "../../assets/partnerLogo/ansarada.svg";
import angelist from "../../assets/partnerLogo/angellist.svg";

import classes from "./about.module.css";
import Hero from "../../component/hero/Hero";

const About = () => {
  const [videoData, setVideoData] = useState([]);

  const getLink = async () => {
    const response = await fetch(
      "https://rea-apis.azurewebsites.net/web/links",
      { mode: "cors" }
    );

    if (!response.ok) {
      alert("error");
      throw new Error("couldn't fetch data");
    }
    const linkData = await response.json();
    setVideoData(linkData);
  };

  useEffect(() => {
    getLink();
  }, []);

  return (
    <>
      <Header />
      <Hero pageTitle={"ABOUT US"} about={true} />

      <section className={classes.about}>
        <section className={classes["container-one"]}>
          <h3>WHAT WE DO</h3>
          <p>
            Founded for the sole purpose of building products to solve real
            problems by leveraging blockchain technology as a solution to real
            world problems. At Neutrolabs, We believe that blockchain is the key
            to solsolving the high entry financial barrier in Africa that cuts
            across every sector. We are passionate about it and our focus is to
            help people achieve financial accessibility through our solutions.
          </p>

          {/* <YouTube videoId="_lrlYtRQYow" /> */}
        </section>
        {videoData.map((item) => (
          <div>
            <iframe
              width="560"
              height="315"
              src={item.link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className={classes.youtube}
            ></iframe>
          </div>
        ))}
        <section className={classes["container-two"]}>
          {" "}
          <h4>OUR</h4>
          <h3>MISSION & VISION</h3>
          <div className={classes["content-two"]}>
            {" "}
            <div className={classes.mission} data-aos="zoom-in-up">
              <h5>MISSION</h5>
              <p>
                Our mission is to help people in Africa achieve financial
                freedom through our solutions, education and community.
              </p>
            </div>
            <div
              className={classes.vision}
              data-aos="zoom-in-up"
              data-aos-duration="1500"
            >
              <h5>VISION</h5>
              <p>
                We want to change the way people think about technology in
                Africa. Neutrolabs believes that technology is key to Africa
                development. Physically, financially and socially. Our vision is
                to change the lives of people in Africa through our solutions.
                Allowing financial accessibility with modern technology.
              </p>
            </div>
          </div>
        </section>

        <section>
          {" "}
          <h4>OUR</h4>
          <h3>PARTNERS</h3>
          <div className={classes["partner-logo"]}>
            <div>
              <img src={azure} alt="azure" />
            </div>

            <div>
              <img src={microsoft365} alt="microsoft365" />
            </div>

            <div>
              <img src={github} alt="github" />
            </div>
            <div>
              <img src={vscode} alt="vscode" />
            </div>
            <div>
              <img src={clarity} alt="clarity" />
            </div>

            <div>
              <img src={openai} alt="openai" />
            </div>

            <div>
              <img src={linkedIn} alt="linkedIn" />
            </div>

            <div>
              <img src={ansarada} alt="ansarada" />
            </div>

            <div>
              <img src={angelist} alt="angellist" />
            </div>
          </div>
        </section>
      </section>

      <Footer home={false} />
    </>
  );
};

export default About;
